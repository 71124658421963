// Scrolls the specified element into view.

export const scrollIntoView = (element, options = {}) => {
    if (element) {
      element.scrollIntoView({
        behavior: options.behavior || "smooth",
        block: options.block || "start",
        inline: options.inline || "nearest",
      });
    }
  };
  